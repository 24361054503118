<template>
    <div>
        <main class="space-y-10 router-view-container">
            <!-- 輕鬆建立您的形象官網 -->
            <div>
                <h1 class="mb-5">3個步驟，輕鬆建立您的形象官網</h1>
                <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'basic_general' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>1. 商店設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state1 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4"><p>填寫您的專屬商店資料</p></div>
                        </router-link>
                    </div>

                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'setting_domains' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>2. 網域設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state2 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4">
                                <p>為您的網站建立出獨有的網址</p>
                            </div>
                        </router-link>
                    </div>

                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'themes' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>3. 佈景主題設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state3 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4">
                                <p>一個適合你品牌的商店設計能夠吸引並留住顧客，選擇一個最適合你品牌的商店設計</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- 輕鬆建立您的電子商務 -->
            <div>
                <h1 class="mb-5">3個步驟，輕鬆建立您的電子商務</h1>
                <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'products' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>1. 商品設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state4 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4">
                                <p>先增加商品名稱及商品圖片來創建你的第一件商品，待完成所有基本設定後探索更多功能</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'store_payments' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>2. 金流設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state5 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4">
                                <p>設定你希望提供予顧客的付款方式</p>
                            </div>
                        </router-link>
                    </div>
                    <div class="div_border flex flex-col">
                        <router-link :to="{ name: 'store_shipping' }" class="h-full">
                            <div class="flex items-center justify-between">
                                <h2>3. 物流設定</h2>
                                <div>
                                    <svg-icon
                                        icon-class="success"
                                        className="xl gray-divide"
                                        v-if="param.ov_state6 == 0"
                                    ></svg-icon>
                                    <svg-icon icon-class="success" className="xl success" v-else></svg-icon>
                                </div>
                            </div>
                            <div class="mt-4">
                                <p>為你的顧客設定不同的送貨方式，每種方式都可以設定不同的收費模式及運費</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- FAQ - 常見問題 -->
            <div v-if="ver === '1'">
                <div class="flex items-center justify-between mb-5">
                    <h1>FAQ - 常見問題</h1>
                    <vs-button @click="active = 0" class="w-28">更多常見問題</vs-button>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-3 div_border gap-4">
                    <div class="space-y-4 text-center">
                        <p v-for="item1 in qaarr1" :key="item1.no">{{ item1.no }}. {{ item1.title }}?</p>
                    </div>
                    <div class="space-y-4 text-center">
                        <p v-for="item2 in qaarr2" :key="item2.no">{{ item2.no }}. {{ item2.title }}?</p>
                    </div>
                    <div class="space-y-4 text-center">
                        <p v-for="item3 in qaarr3" :key="item3.no">{{ item3.no }}. {{ item3.title }}?</p>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'manual',
    data() {
        return {
            param: {},
            qaarr1: {},
            qaarr2: {},
            qaarr3: {},
            ver: process.env.VUE_APP_VER,
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$axios({
                url: 'front/overview/getManual.php',
                headers: {
                    stoken: this.$store.state.login.userInfo.stoken,
                },
                kcval: true,
                data: {
                    lang: 0,
                    dtype: this.dateSelect,
                },
            }).then((res) => {
                if (res.data.Success) {
                    let data = res.data.Data
                    this.param = data.param
                    this.qaarr1 = data.qaarr1
                    this.qaarr2 = data.qaarr2
                    this.qaarr3 = data.qaarr3
                } else {
                    this.$myVS.openNoti('發生錯誤', res.data.Message, 'danger', 10000)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
h1 {
    @apply text-gray-text_dark text-lg font-medium;
}

h2 {
    @apply text-gray-text_dark text-base font-medium;
}

p {
    @apply text-gray-text_light text-sm break-all;
}

.div_border {
    min-height: 120px;
    @apply rounded-xl p-4 bg-white;
}

.bar-chart1 {
    @apply w-11/12 sm:w-4/5;
}

.bar-chart2 {
    @apply w-11/12 sm:w-3/5;
}
</style>
